import React, { useState } from 'react'
import { Modal, Button, Col, Form, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup'
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from 'moment';
import customAxios from '../../customAxios';

const Billformat = ({ openModal, onCloseModal, title }) => {

    const [startDate, setstartDate] = useState(new Date());
    const [loading, setLoading] = useState(false);

    const handleStartDate = (date) => {
        setstartDate(date);
    };

    const schema = yup.object({
        date: yup.date().required('Date is required')
    });

    const { control, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            date: startDate
        }
    });

    const clearclose = () => {
        onCloseModal();
        reset();
    };

    const Submit = async () => {
        let date = moment(startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
    
        try {
            setLoading(true);
            const response = await customAxios.get(`admin/orders/export/${date}`, {
                responseType: 'blob',
            });
    
            // Create a URL for the blob
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
    
            // Suggest a filename for the download
            link.setAttribute('download', `orders_${date}.xlsx`);
            document.body.appendChild(link);
            link.click();
    
            // Clean up
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            clearclose();
        } catch (err) {
            toast.error(err.message);
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <Modal isOpen={openModal} toggle={clearclose} size="sm">
            <ModalHeader toggle={clearclose}>
                <h5 className="modal-title f-w-600" id="exampleModalLabel2">
                    {title}
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                <Label htmlFor="recipient-name" className="col-form-label">
                                    Choose Date
                                </Label>
                                <Controller
                                    name="date"
                                    control={control}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            selected={startDate}
                                            onChange={(date) => {
                                                handleStartDate(date);
                                                field.onChange(date);
                                            }}
                                            className={`form-control ${errors.date ? 'is-invalid' : ''}`}
                                            style={{ width: '100%' }}
                                            showIcon
                                        />
                                    )}
                                />
                                {errors.date && <div className="invalid-feedback">{errors.date.message}</div>}
                            </div>
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit(Submit)}
                    disabled={loading}
                >
                    {loading ? "Downloading..." : "Download"}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default Billformat;
